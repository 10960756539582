<template>
    <el-scrollbar class="main-container">
        <div class="container">
            <div class="first-title">如何获取我的淘宝三段式PID</div>
            <div class="line">阿里妈妈用户如要使用淘宝客推广软件产品服务或淘宝联盟服务，用户的推广代码将采用三段式PID：mm_memberid_siteid_adzoneid。 Memberid对应推广者，Siteid对应媒体，Adzoneid对应推广位。</div>
            <div class="line">
                官方说明：<a href="http://club.alimama.com/read-htm-tid-5579277.html" target="_blank">http://club.alimama.com/read-htm-tid-5579277.html</a>
            </div>

            <div class="second-title">一、登记对应的渠道</div>
            <div class="line">这是新淘客进行推广之前必须要做的，推广渠道分为3种，网站管理，APP管理，导购管理。</div>
            <div class="line">这个大家应该都知道怎么做，在这里就不多做演示了，如有不明白的地方可以查看官方的视频教程。</div>
            <div class="line">
                官方视频教程地址：<a href="http://club.alimama.com/read-htm-tid-5612101.html" target="_blank">http://club.alimama.com/read-htm-tid-5612101.html</a>
            </div>
            <div class="line red">注：阿里妈妈对推广渠道做了强制规定，如果设置错误将无法跟单结算甚至被扣分，请一定用心填写，确保正确。</div>


            <div class="second-title">二、获取三段式PID</div>
            <div class="line">PID主要分为3大类，<span style="text-decoration: underline">普通PID</span>，组件PID（也叫淘点金PID），<span style="text-decoration: line-through">鹊桥PID（已被联盟禁用）</span>。</div>
            <div class="line red">这里我们只需要用到普通PID。</div>
            <div class="line">
                第一步：登录淘宝联盟：<a href="http://www.alimama.com" target="_blank">http://www.alimama.com</a>
            </div>
            <div class="line">
                第二步：进入“超级搜索”商品页面：<a href="http://pub.alimama.com/promo/search/index.htm" target="_blank">http://pub.alimama.com/promo/search/index.htm</a>
            </div>
            <div class="line">第三步：任意选择一件商品点击“立即推广”</div>
            <img :src="require('@/assets/img/pid/tao/1.png')" alt="" style="margin-left: 0">
            <div class="line">第四步：根据你的需求选择网站推广或导购推广，如选择导购推广类型</div>
            <img :src="require('@/assets/img/pid/tao/2.png')" alt="">
            <div class="line red">这里可以选择已有推广位，或是新建推广位。新建推广位的名称可以随意写。点击确定后生成推广链接。</div>
            <div class="line">第五步：复制推广链接</div>
            <img :src="require('@/assets/img/pid/tao/3.png')" alt="">
            <div class="line">第六步：粘贴已复制的推广链接到设置栏中自动提取PID</div>
            <img :src="require('@/assets/img/pid/tao/4.png')" alt="">
        </div>
    </el-scrollbar>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
    * {
        font-size: 16px;
        color: #222;
    }
    .main-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px 10px 0 0;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .container {
        padding: 40px 40px 100px;
        .first-title {
            font-size: 24px;
            margin-bottom: 50px;
        }
        .line {
            margin-top: 20px;
        }
        .second-title {
            margin-top: 40px;
            font-size: 18px;
        }
        .red {
            color: #FE1E00;
        }
    }
    a {
        color: #5A50EF;
    }
    img {
        display: block;
        margin: 20px 0 0 50px;
        max-width: calc(100% - 50px);
    }
</style>